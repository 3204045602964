<template>
	<div>
		<h2>{{ type.contenttype_label }}</h2>

		<!-- <b-form-checkbox class="mt-1 mb-1" v-model="type.web" switch>
			{{ $t('global.afficher_web') }}
		</b-form-checkbox> -->

		<b-tabs content-class="mt-3">
			<b-tab
				v-for="locale in locales"
				:key="locale.code"
				:title="locale.title"
				:active="locale.code === localeSelected.code"
				title-link-class="border"
			>
				<div class="row">
					<div class="col-12">
						<e-editor-froala
							v-if="type.contenttype_type == 'editor'"
			                :placeholder="type.contenttype_label"
			                :content="contents[locale.code]"
			                @change="updateContent($event, locale.code)"
			            />
			            <b-form-input
			            	v-else
			            	type="text"
			                :placeholder="type.contenttype_label"
			            	class="form-control"
			            	v-model="contents[locale.code]"
			            />
			        </div>
		        </div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'WebContentEdit',
		mixins: [],
		props: ['locales', 'contents', 'type', 'update'],
		data () {
			return {
				localeSelected: {
					title: this.$t('global.lang_fr'),
					code: 'fr'
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {

			},

			updateContent(e, lang) {
				this.update(this.type.contenttype_id, lang, e.val)
			},
		},

		components: {
			
		}
	}

</script>